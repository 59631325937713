<template>
  <div class="card" :class="{ [size]: size }">
    <div class="card-wrap">
      <div v-for="item in items" :key="item.id" class="card-item">
        <img :src="item.image" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'HorizontalItemType',
    props: {
      items: {
        type: Array,
        required: true,
        default: () => [],
      },

      size: {
        type: String,
        default: '',
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 259px;
    height: 146px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(151, 174, 202, 0.4);

    @media (max-width: 480px) {
      max-width: 100%;
      height: 184px;
    }

    &.small {
      height: 124px;
      max-width: 220px;
      width: 100%;

      .card-wrap {
        padding: 0px 10px;
        width: auto;

        .card-item {
          width: auto;
          height: 30px;
        }
      }
    }

    &.medium {
      height: 192px;
      max-width: 340px;
      width: 100%;

      .card-wrap {
        // height: 199px;
        padding: 0px 25px;
        width: auto;

        .card-item {
          width: auto;
          height: 50px;
        }
      }
    }

    &.large {
      height: 260px;
      max-width: 460px;
      width: 100%;

      .card-wrap {
        height: auto;
        padding: 0px 25px;
        width: auto;

        .card-item {
          width: auto;
          height: 70px;
        }
      }
    }
  }

  .card-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    align-content: center;
    width: 198px;
    height: 120px;
    // padding-left: 10px;
    row-gap: 9px;
    column-gap: 9px;
  }

  .card-item {
    width: 60px;
    height: 34px;
    // margin: 3px 0 3px -9px;
    background-color: rgba(#000, 0.2);
    overflow: hidden;

    // &:nth-of-type(4),
    // &:nth-of-type(5),
    // &:nth-of-type(6) {
    //   margin-left: 6px;
    // }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
