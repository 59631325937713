<template src="./swiper-carousel.html" />
<script>
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
  import CarouselItem from './carousel-item/carousel-item';
  import MoreItem from './more-item/more-item';

  import { DEFAULT_CAROUSEL_CONFIG } from '@/config/carousel.config';
  import { amsClient } from '@/service/ams';
  import {
    SMALL_PRODUCTS_CAROUSEL_ITEMS_CONFIG,
    MEDIUM_PRODUCTS_CAROUSEL_ITEMS_CONFIG,
    LARGE_PRODUCTS_CAROUSEL_ITEMS_CONFIG,
  } from 'src/config/size-carousel.config';

  export default {
    name: 'SwiperCarousel',
    components: {
      Swiper,
      SwiperSlide,
      CarouselItem,
      MoreItem,
    },
    directives: {
      swiper: directive,
    },
    props: {
      area: {
        type: Object,
        default: () => {
        },
      },
      items: {
        type: Array,
        required: true,
        default: () => [],
      },
      itemsType: {
        type: String,
        default: () => 'horizontal',
      },
      config: {
        type: Object,
        default: () => DEFAULT_CAROUSEL_CONFIG,
      },

      hideShowMore: {
        type: Boolean,
        default: false,
      },

      hideArrowsControls: {
        type: Boolean,
        default: false,
      },

      link: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isHovered: false,
        isVisible: false,
        viewWidth: 0,
      };
    },
    computed: {
      itemsAlign() {
        if (this.area?.itype === 'section_shopitem_shoprz' || this.area?.itype === 'section_shoprz') {
          let breakpoints = SMALL_PRODUCTS_CAROUSEL_ITEMS_CONFIG.breakpoints;

          if (this.area.size === 'medium') {
            breakpoints = MEDIUM_PRODUCTS_CAROUSEL_ITEMS_CONFIG.breakpoints;
          } else if (this.area.size === 'large') {
            breakpoints = LARGE_PRODUCTS_CAROUSEL_ITEMS_CONFIG.breakpoints;
          }

          const breakPointsKeys = Object.keys(breakpoints).sort((a, b) => b - a).map(Number);
          const viewBreakpoint = breakPointsKeys.find((key) => this.viewWidth >= key);
          const nitems = breakpoints[viewBreakpoint]?.slidesPerView;

          return this.items.length < nitems ? this.area?.align : 'left';
        }
        return 'left';
      },
    },
    mounted() {
      this.checkWidth();
      this.$bus.$on('resizeWindow', this.checkWidth);
    },
    unmounted() {
      this.$bus.$off('resizeWindow');
    },
    methods: {
      checkWidth() {
        this.viewWidth = window.innerWidth;
      },
      changeSlide() {
        this.$bus.$emit('closeProduct');
        const swiper = this.$refs.swiper.$swiper;

        if (+swiper.currentBreakpoint > 300) {
          return;
        }

        if (this.$refs.carouselItem && this.$refs.carouselItem[swiper.activeIndex].canPlay()) {
          this.$refs.carouselItem[swiper.activeIndex].runPreview();
        }

        this.$emit('change:slide', swiper.activeIndex); //swiper.activeIndex  must have for video-gallery.component
      },

      observeVisibility(isVisible, entry, firstItem) {
        if (firstItem?.itype !== 'item_mov_vod_shoprz') {
          return;
        }

        const swiper = this.$refs.swiper.$swiper;

        this.isVisible = isVisible;

        if (isVisible && +swiper.currentBreakpoint <= 300) {
          if (this.$refs.carouselItem[swiper.activeIndex].canPlay()) {
            this.$refs.carouselItem[swiper.activeIndex].runPreview();
          }
        }

        if (!isVisible) {
          this.$refs.carouselItem[swiper.activeIndex].stopPreview();
        }
      },
      async onItemClick(item, index, $event) {
        const newItem = { ...item };

        if (typeof newItem.creator === 'string') {
          if (newItem.storefront) {
            newItem.creator = { ...newItem.storefront };
          } else {
            const response = await amsClient.callAms(newItem.creator);
            newItem.creator = { ...response.head };
          }
        }

        if (newItem?.itype?.includes('vod')) {
          //   // const path = this.$route.path !== '/' ? this.$route.path : '';
          //   this.$bus.$emit('togglePopupVOD', {
          //     item: {
          //       ...newItem,
          //       // ...(!newItem.share && {
          //       //   share: `${path}/v/${newItem.slug}/`,
          //       // }),
          //     },
          //     options: {
          //       startPosition: ($event?.time_in || 0) / 1000,
          //     },
          //   });
          //   this.$bus.$emit('headerHide');
          this.$router.push(`/s/${(newItem.storefront || newItem.creator)?.slug}/v/${newItem.slug || newItem.id}`);
          // this.$router.push(`/v/${newItem.slug || newItem.id}`);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .carousel {
    display: block;
    margin-bottom: 30px;
    min-height: 217px;

    &.carousel-align-center {
      &::v-deep .swiper-wrapper {
        justify-content: center;
      }
    }

    &.carousel-align-right {
      &::v-deep .swiper-wrapper {
        justify-content: end;
      }
    }

    &::v-deep .swiper-container {
      overflow: unset;
    }

    .more-btn {
      // padding: 16px 20px 22px;
      display: block;

      .card {
        border-radius: 10px;
      }
    }

    .swiper-action {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 3px solid #fff;
      background-color: $container-background-color;

      @media (max-width: 768px) {
        display: none !important;
      }

      &:hover {
        background-color: $main-color;

        &:after {
          color: $white;
        }
      }

      &:after {
        font-size: 14px;
        font-weight: 900;
      }

      &.swiper-button-prev {
        left: -26px;
      }

      &.swiper-button-next {
        right: -26px;
      }

      &.swiper-button-disabled {
        visibility: hidden !important;
        opacity: 0 !important;
      }
    }
  }

  @media (min-width: 1024px) {
    .carousel {
      margin-bottom: 52px;
    }
  }
</style>
